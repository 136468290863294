/*copy from element-ui@2.15.13/packages/theme-chalk/descriptions-item.css */

.el-descriptions-item {
  vertical-align: top
}

.el-descriptions-item__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.el-descriptions-item__container .el-descriptions-item__content,
.el-descriptions-item__container .el-descriptions-item__label {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline
}

.el-descriptions-item__container .el-descriptions-item__content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1
}

.el-descriptions-item__label.has-colon::after {
  content: ':';
  position: relative;
  top: -.5px
}

.el-descriptions-item__label.is-bordered-label {
  font-weight: 700;
  color: #909399;
  background: #fafafa
}

.el-descriptions-item__label:not(.is-bordered-label) {
  margin-right: 10px
}

.el-descriptions-item__content {
  word-break: break-word;
  overflow-wrap: break-word
}
