@font-face {
  font-family: "iconfont2";
  src: url("~/assets/fonts/legacy/iconfonts/iconfont2.eot"); /* IE9*/
  src: url("~/assets/fonts/legacy/iconfonts/iconfont2.eot#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("~/assets/fonts/legacy/iconfonts/iconfont2.woff2") format("woff2"),
  url("~/assets/fonts/legacy/iconfonts/iconfont2.woff") format("woff"),
  url("~/assets/fonts/legacy/iconfonts/iconfont2.ttf") format("truetype"), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url("~/assets/fonts/legacy/iconfonts/iconfont2.svg#iconfont2") format("svg"); /* iOS 4.1- */
}

[class^="iconfont2-"], [class*=" iconfont2-"] {
  font-family: 'iconfont2' !important;
  font-size:16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.iconfont2-she_bei_fen_xi:before { content: "\ea01"; }
