.personne-mangent-main .el-table.el-table--fit {
  padding: 0 24px;
}

.personne-mangent-main .el-table__header th {
  background-color: rgba(192, 196, 204, 0.08);
}

.personne-mangent-main .el-table__header th:first-child:not(.el-table-column--selection), .personne-mangent-main .el-table__body td:first-child:not(.el-table-column--selection) {
  padding-left: 24px;
}


.personne-mangent-main table.el-table__header, .personne-mangent-main table.el-table__body {
  width: 100% !important;
  /*table-layout: auto;*/
}

.personne-mangent-main .el-table .el-table-column--selection .cell {
  padding-left: 14px;
  padding-right: 14px;
}
