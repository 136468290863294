.activities-main.base-main .main-box .title {
	height: 38px;
	line-height: 38px;
	box-sizing: content-box;
}

.activities-main.base-main .main-box .title .el-divider--horizontal {
	margin-top: 12px;
}

.activities-main.base-main .main-box .none {
	margin: 90px auto;
}