@import "./element-ui/theme-chalk.css";
@import "./iconfont2.css";

@import "./fix/fix_MenuBar.css";
@import "./fix/fix_NavBar.css";
@import "./fix/fix_Header.css";
@import "./fix/fix_XiTongPeiZhi_1.css";
@import "./fix/fix_YongHuFenXi_1.css";
@import "./fix/fix_YunYingHuoDong.css";


/* v2优先级高于源码
这里再次引用theme-chalk.css导致源码el-input__inner中的height: 32px 失效
所以v2 公共样式再次声明 el-input__inner  height: 32px;
*/
.el-input__inner {
  height: 32px;
  line-height: 32px;
}
