.breadcrumb-box {
  height: 108px;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06) inset;

  & .el-breadcrumb__inner a,
  & .el-breadcrumb__inner.is-link {
    color: #91939a !important;
  }

  & .el-breadcrumb__item:last-child {
    & .el-breadcrumb__inner {
      color: #333 !important;
    }
  }

  & .menu-now {
    margin-top: 24px;
    font-weight: bold;
  }

  @media screen and (max-width: 768px) {
    & {
      height: 58px;
      padding-top: 15px !important;
      padding-bottom: 15px !important;

      & .menu-now {
        margin-top: 0;
      }
    }
  }
}

