.activities-detail-main {
  &>.main-box {
    &>.main-page.chart {
      &>.chart-left {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        &>.left-item {
          width: 48%;
        }
      }
    }
  }
}
