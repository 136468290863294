.navbar-box .sidebar-btn i.icondakaicaidan {
  font-size: 22px;
}

.log-out-btn.fs26 {
  font-size: 20px !important;
}

.navbar-box .user-msg .user-name.fs16 {
  top: -1px;
  font-weight: 600;
}