/*copy from element-ui@2.15.13/packages/theme-chalk/descriptions.css */

.el-descriptions-item {
  vertical-align: top
}

.el-descriptions-item__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.el-descriptions-item__container .el-descriptions-item__content,
.el-descriptions-item__container .el-descriptions-item__label {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline
}

.el-descriptions-item__container .el-descriptions-item__content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1
}

.el-descriptions-item__label.has-colon::after {
  content: ':';
  position: relative;
  top: -.5px
}

.el-descriptions-item__label.is-bordered-label {
  font-weight: 700;
  color: #909399;
  background: #fafafa
}

.el-descriptions-item__label:not(.is-bordered-label) {
  margin-right: 10px
}

.el-descriptions-item__content {
  word-break: break-word;
  overflow-wrap: break-word
}

.el-descriptions {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  color: #303133
}

.el-descriptions__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px
}

.el-descriptions__title {
  font-size: 16px;
  font-weight: 700
}

.el-descriptions--mini,
.el-descriptions--small {
  font-size: 12px
}

.el-descriptions__body {
  color: #606266;
  background-color: #FFF
}

.el-descriptions__body .el-descriptions__table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed
}

.el-descriptions__body .el-descriptions__table .el-descriptions-item__cell {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;
  font-weight: 400;
  line-height: 1.5
}

.el-descriptions__body .el-descriptions__table .el-descriptions-item__cell.is-left {
  text-align: left
}

.el-descriptions__body .el-descriptions__table .el-descriptions-item__cell.is-center {
  text-align: center
}

.el-descriptions__body .el-descriptions__table .el-descriptions-item__cell.is-right {
  text-align: right
}

.el-descriptions .is-bordered {
  table-layout: auto
}

.el-descriptions .is-bordered .el-descriptions-item__cell {
  border: 1px solid #EBEEF5;
  padding: 12px 10px
}

.el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  padding-bottom: 12px
}

.el-descriptions--medium.is-bordered .el-descriptions-item__cell {
  padding: 10px
}

.el-descriptions--medium:not(.is-bordered) .el-descriptions-item__cell {
  padding-bottom: 10px
}

.el-descriptions--small.is-bordered .el-descriptions-item__cell {
  padding: 8px 10px
}

.el-descriptions--small:not(.is-bordered) .el-descriptions-item__cell {
  padding-bottom: 8px
}

.el-descriptions--mini.is-bordered .el-descriptions-item__cell {
  padding: 6px 10px
}

.el-descriptions--mini:not(.is-bordered) .el-descriptions-item__cell {
  padding-bottom: 6px
}
